import React from "react";
import NavBar from "./Navbar/NavBar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const IntAuto = () => {
  return (
    <>
      <NavBar />
      <br />
      <br />
      <section data-aos="zoom-in-down" className="py-12">
        {/* Heading */}
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-custom-blue uppercase font-bold">
            Intelligent Automation
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-custom-blue"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-extrabold text-gray-700">
            Revolutionizing the Future of Business
          </h2>
        </div>
        {/* First head */}
        <div
          className="px-12 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
          data-aos="fade-down"
          data-aos-delay="600"
        >
          At the heart of digital transformation lies{" "}
          <b>Intelligent Automation (IA)</b>—a blend of artificial intelligence
          (AI) and advanced automation technologies designed to optimize
          operations, boost productivity, and unlock new business opportunities.
          IA is more than just a tool; it’s a strategy for organizations aiming
          to thrive in an increasingly competitive and complex global market.{" "}
          <br />
          <br /> Explore how Intelligent Automation can empower your business to
          achieve unparalleled efficiency, improve customer satisfaction, and
          stay ahead of the curve.
        </div>
        {/* hr */}
        <div className="flex justify-center">
          <div className="w-[90%] mt-7 border-b-4 border-custom-blue"></div>
        </div>
        {/* second head */}
        <div className="py-4 my-4">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-extrabold  text-gray-700">
            What Is Intelligent Automation?
          </h2>
          <div
            className="px-12 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            Intelligent Automation combines the speed and precision of Robotic
            Process Automation (RPA) with the cognitive capabilities of AI, such
            as machine learning (ML), natural language processing (NLP), and
            computer vision. This advanced integration allows businesses to
            automate not only routine tasks but also decision-making processes
            and complex workflows.
            <br />
            <br />
            For example:
            <div className="border-2 rounded-lg">
              <ul className="p-4 flex flex-col gap-3">
                <li>
                  <b>Healthcare Providers:</b> Use IA to manage patient records,
                  support diagnostics, and streamline billing processes.
                </li>
                <li>
                  <b>Retailers:</b> Enhance customer experiences with
                  personalized recommendations and automated inventory
                  management.
                </li>
                <li>
                  <b>Manufacturers:</b> Improve production efficiency through
                  predictive maintenance and quality control. IA offers a
                  tailored solution for every industry, turning inefficiencies
                  into opportunities for growth.
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* hr  */}
        <div className="flex justify-center">
          <div className="w-[90%] mt-7 border-b-4 border-custom-blue"></div>
        </div>
        {/* third head */}
        <div className="py-4 my-4">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-extrabold  text-gray-700">
            How Does Intelligent Automation Work?
          </h2>
          <div
            className="px-12 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <p className=" text-center">
              IA integrates a range of technologies and techniques to deliver
              robust automation solutions:
            </p>
          </div>
          <div className="px-12 py-7" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  <h2 className="font-semibold my-4 text-xl text-center">
                    Data Collection
                  </h2>
                  <div className="text-md font-medium">
                    Collects and organizes data from multiple sources—structured
                    and unstructured.
                  </div>
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  <h2 className="font-semibold my-4 text-xl text-center">
                    Analysis and Decision-Making
                  </h2>
                  <div className="text-md font-medium">
                    AI models analyze data to make recommendations or trigger
                    automated responses.
                  </div>
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  <h2 className="font-semibold my-4 text-xl text-center">
                    Process Execution
                  </h2>
                  <div className="text-md font-medium">
                    RPA bots carry out repetitive tasks with accuracy and speed,
                    following pre-set instructions.
                  </div>
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  <h2 className="font-semibold my-4 text-xl text-center">
                    Continuous Improvement
                  </h2>
                  <div className="text-md font-medium">
                    Machine learning continuously refines processes based on
                    outcomes, ensuring scalability and adaptability.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="px-12 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <p>
              <b>Example</b>: A financial institution can combine RPA with AI to
              automatically process loan applications, validate customer
              information, and flag potential risks—all while maintaining
              compliance with regulatory standards.
            </p>
          </div>
        </div>
        {/* hr  */}
        <div className="flex justify-center">
          <div className="w-[90%] mt-7 border-b-4 border-custom-blue"></div>
        </div>
        {/* fourth head */}
        <div className="py-4 my-4">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-extrabold  text-gray-700">
            Key Benefits of Intelligent Automation
          </h2>
          <div
            className="px-14 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
            data-aos="fade-down"
            data-aos-delay="600"
          >
            <b>1. Enhanced Efficiency and Productivity</b> IA minimizes human
            intervention in repetitive tasks, allowing employees to focus on
            strategic and creative initiatives. With faster execution and fewer
            errors, businesses achieve higher productivity levels across
            departments.
            <br />
            <br />
            2. <b>Superior Customer Experiences</b> By leveraging AI-driven
            chatbots, personalized marketing strategies, and quick response
            times, IA enhances customer satisfaction and loyalty. AI insights
            also enable businesses to anticipate customer needs, delivering a
            seamless and customized experience.
            <br />
            <br />
            3. <b>Cost Optimization</b> Automating tasks reduces operational
            costs by lowering the need for manual labor and decreasing error
            rates. It also optimizes resource allocation, allowing businesses to
            scale without significant overhead increases.
            <br />
            <br />
            4.<b> Data-Driven Decision-Making</b> IA leverages real-time data to
            provide actionable insights, helping leaders make informed
            decisions. Predictive analytics, powered by machine learning,
            ensures businesses can anticipate market trends and adjust
            strategies proactively.
            <br />
            <br />
            5. <b>Agility and Scalability</b> IA allows businesses to adapt to
            changes quickly, whether it’s market dynamics, customer demands, or
            regulatory requirements. Its scalability ensures that businesses can
            grow their operations without adding complexity.
          </div>
        </div>
        {/* hr  */}
        <div className="flex justify-center">
          <div className="w-[90%] mt-7 border-b-4 border-custom-blue"></div>
        </div>
        {/* fifth head */}
        <div className="my-4 py-4">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-extrabold  text-gray-700">
            Components of Intelligent Automation
          </h2>
          <div
            className="px-14 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            To fully understand IA, it’s essential to recognize the technologies
            that power it:
            <br />
            <br />
            <ul className=" lg:ml-20">
              <li>
                <b>• Robotic Process Automation (RPA):</b> Automates repetitive
                and rule-based tasks such as data entry, invoice processing, and
                reporting.{" "}
              </li>
              <br />
              <li>
                <b>• Artificial Intelligence (AI):</b> Mimics human intelligence
                to perform complex tasks like language translation, sentiment
                analysis, and predictive modeling.{" "}
              </li>
              <br />
              <li>
                <b>• Machine Learning (ML):</b> Enhances decision-making by
                analyzing data patterns and improving processes over time.{" "}
              </li>
              <br />
              <li>
                <b>• Natural Language Processing (NLP):</b> Enables machines to
                understand, interpret, and generate human language for tasks
                like chatbot conversations or document analysis.{" "}
              </li>
              <br />
              <li>
                <b>• Computer Vision:</b>
                Processes visual inputs like images and videos for use in
                quality inspection, security, or inventory tracking.{" "}
              </li>
              <br />
              <li>
                {" "}
                <b>• Process Mining:</b>
                Discovers inefficiencies in workflows, providing actionable
                insights to optimize them further.
              </li>
            </ul>
          </div>
        </div>
        {/* hr  */}
        <div className="flex justify-center">
          <div className="w-[90%] mt-7 border-b-4 border-custom-blue"></div>
        </div>
        {/* sixth head  */}
        <div className="my-4 py-4">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-extrabold  text-gray-700">
            Use Cases Across Industries
          </h2>
          <div
            className="px-14 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <p className="text-center">
              To fully understand IA, it’s essential to recognize the
              technologies that power it:
            </p>
            <br />
            <ul className="border-2 rounded-lg px-8 py-5">
              <li>
                <b>1. Healthcare</b>
                <br />
                <p className="p-5 ">
                  • Automating appointment scheduling, claims processing, and
                  patient data management.
                  <br />• Using AI to assist in diagnostics and predict patient
                  outcomes.
                </p>
              </li>
              <br />
              <li>
                <b>2. Finance</b>
                <br />
                <p className="p-5">
                  • Fraud detection through pattern recognition.
                  <br />• End-to-end loan processing, including credit scoring
                  and approvals.
                </p>
              </li>
              <br />
              <li>
                <b>3. Retail</b>
                <br />
                <p className="p-5">
                  • Inventory management and replenishment based on predictive
                  analytics.
                  <br />• Enhancing customer service with AI-driven virtual
                  assistants.
                </p>
              </li>
              <br />
              <li>
                <b>4. Manufacturing</b>
                <br />
                <p className="p-5">
                  • Monitoring equipment for predictive maintenance.
                  <br />• Optimizing production schedules and supply chain
                  operations.
                </p>
              </li>
              <br />
              <li>
                <b>5. Transportation and Logistics</b>
                <br />
                <p className="p-5">
                  • Route optimization using real-time traffic and weather data.
                  <br />• Automating freight tracking and warehouse operations.
                </p>
              </li>
            </ul>
          </div>
        </div>
        {/* hr  */}
        <div className="flex justify-center">
          <div className="w-[90%] mt-7 border-b-4 border-custom-blue"></div>
        </div>
        {/* seventh head  */}
        <div className="my-4 py-4">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-extrabold  text-gray-700">
            The Future of Intelligent Automation
          </h2>
          <div
            className="px-14 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <p>
              Intelligent Automation is not just a passing trend; it is the
              future of how businesses operate. With advancements in AI, IA is
              set to become even more powerful, offering:
            </p>
            <br />
            <ul>
              <li>
                <b>• Smarter Decision-Making: </b>
                Improved AI algorithms for strategic insights.
              </li>
              <br />
              <li>
                <b>• Pre-Packaged Solutions: </b>
                Industry-specific IA tools that reduce time to deploy.
              </li>
              <br />
              <li>
                <b>• Better ROI: </b>
                Enhanced monitoring tools to track the effectiveness and value
                of IA investments.
              </li>
              <br />
              <p>
                Organizations that adopt IA today are investing in a more agile,
                efficient, and innovative future.
              </p>
            </ul>
          </div>
        </div>
        {/* hr  */}
        <div className="flex justify-center">
          <div className="w-[90%] mt-7 border-b-4 border-custom-blue"></div>
        </div>
        {/* eight head  */}
        <div className="my-4 py-4">
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-extrabold  text-gray-700">
            Partner with Us to Embrace Intelligent Automation
          </h2>
          <div
            className="px-14 mt-4 max-w-8xl text-xl text-gray-500 lg:mx-auto space-y-4"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            At Boticx labs, we specialize in designing and implementing
            Intelligent Automation solutions tailored to your business needs.
            With expertise in RPA, AI, and end-to-end automation, we help
            organizations unlock their full potential. 
            <br/>
            <br/>
            Let us guide your
            transformation journey. Contact us today to explore how Intelligent
            Automation can drive measurable impact for your business.
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default IntAuto;
