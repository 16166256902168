import React from "react";
import NavBar from "./Navbar/NavBar";
import Footer from "./Footer";

const KnowMore = () => {
  return (
    <>
      <NavBar />
      <br />
      <br />
      <section data-aos="zoom-in-down" className="py-12">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-custom-blue uppercase font-bold">
            Welcome to Boticx
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-custom-blue"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-custom-blue">
            Where Innovation Meets Deep Technologies.
          </h2>
        </div>
        <div
          className="px-12 leading-6"
          data-aos="fade-down"
          data-aos-delay="600"
        >
          At <b>Boticx Labs</b>, we are transforming industries through
          intelligent automation and advanced AI solutions. By combining AI and
          Robotic Process Automation (RPA), we streamline workflows and optimize
          decision-making across sectors like healthcare, finance, and retail,
          boosting efficiency and customer satisfaction. Our{" "}
          <b>Generative AI</b> drives innovation by generating content and
          improving interactions, while our <b>AI-driven product development</b>{" "}
          accelerates design and enhances quality control. We leverage{" "}
          <b>Edge Computing</b> to provide real-time insights and ensure secure,
          uninterrupted operations, and with <b>AIoT</b>, we empower industries
          like precision agriculture, smart cities, and manufacturing with
          autonomous decision-making and data-driven solutions.
          <br />
          <br />
          At <b>Boticx Labs</b>, our research focuses on revolutionizing
          industries through groundbreaking technological innovations. In civil
          engineering, we empower automation by enabling the generation and
          seamless download of CAD files from simple Excel inputs, streamlining
          design workflows and saving valuable time. Our AIoT solutions are
          transforming tea estates, integrating smart sensors and data analytics
          to enhance productivity and sustainability. For the healthcare sector,
          we develop pioneering ideas that redefine patient care and operational
          efficiency, paving the way for smarter hospitals. In the education
          domain, our innovative lab solutions foster a new era of experiential
          learning, equipping future generations with cutting-edge tools to
          explore, innovate, and grow. By merging technology with industry
          needs, we are shaping a future where automation, intelligence, and
          creativity thrive together.
          <br />
          <br />
          At Boticx Labs, we are not just solving problems; we’re creating the
          future. By blending innovation, intelligence, and automation, we’re
          paving the way for smarter businesses and a more efficient world.
        </div>
      </section>
      <Footer />
    </>
  );
};

export default KnowMore;
